import React, { FC, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingsActions from "../../../store/actions/settingsActions";
import { Meal, Portion } from "../../../store/reducers/menuState";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import PortionRow from "./PortionRow";
import { IconButton, List, ListItem, SelectChangeEvent } from "@mui/material";
import { FormControl, Modal, ModalClose, ModalDialog, ModalOverflow, Typography } from "@mui/joy";
import { Add, Delete, Save } from "@mui/icons-material";

interface UserModalProps {
    open: boolean;
    isAddMode: boolean;
    selectedMeal: Meal;
    onHandleClose: () => void;
}

const MealModal: FC<UserModalProps> = ({ open, isAddMode, selectedMeal, onHandleClose }) => {
    const [mealData, setMealData] = useState<Meal>({
        id: "",
        name: "",
        meal: "",
        description: "",
        imageUrl: "",
        portions: [],
        soldOut: false
    });
    const [nameError, setNameError] = useState<string>("");

    const dispatch = useDispatch();
    const { addMeal } = bindActionCreators(settingsActions, dispatch);

    useEffect(() => {
        if (!isAddMode) {
            setMealData(selectedMeal);
        }
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setMealData({ ...mealData, [name]: value });
        if (name === "name") {
            setNameError("");
        }
    };

    const handleAddPortionRow = (): void => {
        const id: string = uuidv4();
        const portions: Portion[] = [...mealData.portions, { id, size: "", price: 0 }];
        setMealData({ ...mealData, portions });
    };

    const handleRemovePortionRow = (id: string): void => {
        const portions: Portion[] = mealData.portions.filter(portion => portion.id !== id);
        setMealData({ ...mealData, portions });
    };

    const handlePortionSelectChange = (e: SelectChangeEvent, i: number): void => {
        const portions: Portion[] = [...mealData.portions];
        portions[i].size = e.target.value;
        setMealData({ ...mealData, portions });
    };

    const handlePortionInputChange = (e: React.ChangeEvent<HTMLInputElement>, i: number): void => {
        const portions: Portion[] = [...mealData.portions];
        portions[i].price = Number(e.target.value);
        setMealData({ ...mealData, portions });
    };

    const handleAddOrEditMeal = (e: React.FormEvent): void => {
        e.preventDefault();

        if (mealData.name.trim()) {
            addMeal(mealData, isAddMode);
            onHandleClose();
        } else {
            setNameError("Polje je obavezno.");
        }
    };

    return (
        <Modal open={open}>
            <ModalOverflow>
                <ModalDialog
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ minWidth: { sm: "600px" } }}
                >
                    <ModalClose variant="outlined" onClick={onHandleClose} />
                    <Typography id="basic-modal-dialog-title" level="h2">
                        {isAddMode ? "Dodaj novo jelo" : "Izmeni jelo"}
                    </Typography>
                    <Input
                        label="Ime"
                        type="text"
                        value={mealData.name}
                        name="name"
                        required
                        error={nameError}
                        onChange={handleInputChange}
                    />
                    <Input
                        label="Opis"
                        type="text"
                        value={mealData.description}
                        name="description"
                        onChange={handleInputChange}
                    />
                    <Input
                        label="Putanja slike"
                        type="text"
                        value={mealData.imageUrl}
                        name="imageUrl"
                        onChange={handleInputChange}
                    />
                    <FormControl sx={{ my: 1.5 }}>
                        <Button
                            label="Dodaj porciju"
                            startIcon={<Add />}
                            className="btn_success"
                            handleClick={handleAddPortionRow}
                        />
                    </FormControl>
                    <List>
                        {mealData.portions.map((portion, i) =>
                            <React.Fragment key={portion.id}>
                                <ListItem
                                    inputMode="numeric"
                                    divider
                                    sx={{ maxWidth: 760, bgcolor: "#fff" }}
                                    secondaryAction={
                                        <IconButton
                                            sx={{ ml: 1 }}
                                            size="small"
                                            color="error"
                                            onClick={() => { handleRemovePortionRow(portion.id) }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    }
                                >
                                    <PortionRow
                                        index={i}
                                        portion={portion}
                                        handlePortionSelectChange={handlePortionSelectChange}
                                        handlePortionInputChange={handlePortionInputChange}
                                    />
                                </ListItem>
                            </React.Fragment>
                        )}
                    </List>
                    <FormControl sx={{ mt: 5 }}>
                        <Button
                            label="Sačuvaj"
                            startIcon={<Save />}
                            className="btn_success"
                            handleClick={handleAddOrEditMeal}
                        />
                    </FormControl>
                </ModalDialog>
            </ModalOverflow>
        </Modal>
    );
};

export default MealModal;