import React, { FC, useState } from "react";
import TermsOfUseModal from "./TermsOfUseModal";
import Checkbox from "../../UI/Checkbox";
import { FormControl } from "@mui/material";

interface TermsOfUseCheckboxProps {
    checked: boolean;
    error?: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TermsOfUseCheckbox: FC<TermsOfUseCheckboxProps> = ({ checked, error, handleChange }) => {
    const [openTermsOfUseModal, setOpenTermsOfUseModal] = useState<boolean>(false);

    return (
        <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
            <Checkbox
                checked={checked}
                onChange={handleChange}
            />
            <div>
                <span>Prihvatam </span>
                <span
                    onClick={() => setOpenTermsOfUseModal(true)}
                    className="terms_of_use_link"
                >
                    Uslove korišćenja
                </span>
            </div>
            {error && <div className="error_message" style={{ top: "35px" }}>{error}</div>}

            <TermsOfUseModal
                open={openTermsOfUseModal}
                onHandleClose={() => setOpenTermsOfUseModal(false)}
            />
        </FormControl>
    );
};

export default TermsOfUseCheckbox;