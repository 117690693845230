import React, { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/settingsActions";
import { Meal } from "../../store/reducers/menuState";
import moment, { Moment } from "moment";
import DatePicker from "../UI/DatePicker";
import Autocomplete from "../UI/Autocomplete";
import Button from "../UI/Button";
import Checkbox from "../UI/Checkbox";
import { FormControl, IconButton, List, ListItem } from "@mui/material";
import { Add, Delete, Save } from "@mui/icons-material";
import { toast } from "react-toastify";

interface MenuFormProps {
    meals: Meal[];
}

const MenuForm: FC<MenuFormProps> = ({ meals }) => {
    const [dateValue, setDateValue] = useState<Moment>(moment().add(1, "days"));
    const [meal, setMeal] = useState<Meal | null>(null);
    const [mealsForMenu, setMealsForMenu] = useState<Meal[]>([]);

    const dispatch = useDispatch();
    const { addMenu } = bindActionCreators(actions, dispatch);

    const handleAutoCompleteChange = (e: React.ChangeEvent<{}>, value: Meal | null) => {
        setMeal(value);
    };

    const handleAddMeal = (): void => {
        if (meal) {
            const mealExists: boolean = mealsForMenu.some(m => m.id === meal.id);
            if (!mealExists) {
                setMealsForMenu([...mealsForMenu, meal]);
            } else {
                toast.error("Navedeno jele je već uneto.");
            }
        }
    };

    const handleCheckboxChange = (mealId: string) => {
        setMealsForMenu((prevMeals) =>
            prevMeals.map((meal) =>
                meal.id === mealId
                    ? { ...meal, nutritionisRecommended: !meal.nutritionisRecommended }
                    : meal
            )
        );
    };

    const handleRemoveMeal = (id: string): void => {
        setMealsForMenu(mealsForMenu.filter(m => m.id !== id));
    };

    const handleAddMenu = (): void => {
        //Additional meals for every menu
        const extraMeals: Meal[] = [
            {
                id: "1ea4c7e3-8c2f-4c34-8aa3-fba74e59a03e",
                name: "Pomfrit/začinski krompir (kečap opciono)",
                meal: "extraPotatoe",
                soldOut: false,
                description: "",
                imageUrl: "",
                portions: [
                    {
                        id: "9f4303f4-2c72-4b06-af33-8f6606aeb62a",
                        size: "regular",
                        price: 200
                    }
                ]
            },
            {
                id: "3675af31-d4f2-4c6d-8eef-671ef070bafe",
                name: "Dodatni hleb",
                meal: "extraBread",
                soldOut: false,
                description: "",
                imageUrl: "",
                portions: [
                    {
                        id: "e8a877fb-facd-4ad2-9f39-eaa0f8da6f16",
                        size: "regular",
                        price: 40
                    }
                ]
            },
            {
                id: "c7e986d0-bc89-4d89-9ce5-93996847be25",
                name: "Kupus salata",
                meal: "extraSalad",
                soldOut: false,
                description: "",
                imageUrl: "",
                portions: [
                    {
                        id: "8f3887da-4299-41a1-9f20-a95c5cbc92c4",
                        size: "regular",
                        price: 120
                    }
                ]
            },
            {
                id: "27290639-d26b-41e8-ae9c-48db3ecae2db",
                name: "Paradajz + krastavci",
                meal: "extraSalad",
                soldOut: false,
                description: "",
                imageUrl: "",
                portions: [
                    {
                        id: "00f25b2d-1158-4f2b-acc6-7d3549329ede",
                        size: "regular",
                        price: 150
                    }
                ]
            },
            {
                id: "529c75a9-e7c6-4875-9d44-635b7fef50ab",
                name: "Paradajz + luk",
                meal: "extraSalad",
                soldOut: false,
                description: "",
                imageUrl: "",
                portions: [
                    {
                        id: "56d4c3d9-7bd8-45cc-b4ab-957111ddb0cc",
                        size: "regular",
                        price: 150
                    }
                ]
            },
            {
                id: "b22afb2a-23ae-4a6e-8a08-14551946876c",
                name: "Krastavac u pavlaci",
                meal: "extraSalad",
                soldOut: false,
                description: "",
                imageUrl: "",
                portions: [
                    {
                        id: "6b0f8f73-c104-4bc5-8264-1d71b1f161b8",
                        size: "regular",
                        price: 150
                    }
                ]
            },
            {
                id: "e31f785c-8c00-4e76-9942-057c08977406",
                name: "Crvena pečena paprika (2 kom)",
                soldOut: false,
                meal: "extraSalad",
                description: "",
                imageUrl: "",
                portions: [
                    {
                        id: "d3157925-e78b-4db4-96fe-16e8a99003c2",
                        size: "regular",
                        price: 200
                    }
                ]
            },
            {
                id: "89fffb4d-25b3-4452-a5cf-e76e6286bd08",
                name: "Ljuta pečena paprika (2 kom)",
                soldOut: false,
                meal: "extraSalad",
                description: "",
                imageUrl: "",
                portions: [
                    {
                        id: "d97f7f07-7ac4-463a-b454-e486625df698",
                        size: "regular",
                        price: 200
                    }
                ]
            }
        ];

        addMenu([...mealsForMenu, ...extraMeals], dateValue.startOf("day").unix());
    };

    const filteredMeals = useMemo(() => meals.filter(meal => meal.meal !== "oatmeal"), [meals]);

    return (
        <div>
            <div className="input_container">
                <DatePicker
                    value={dateValue}
                    minDate={moment().add(1, "days")}
                    onChange={setDateValue}
                />
                <Autocomplete<Meal>
                    options={filteredMeals}
                    label="Jela"
                    value={meal}
                    onChange={handleAutoCompleteChange}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                />
                <Button
                    label="Dodaj jelo"
                    startIcon={<Add />}
                    disabled={!meal}
                    className="btn_success"
                    handleClick={handleAddMeal}
                />
            </div>
            {mealsForMenu.length > 0 &&
                <div className="selected_meals_container">
                    <h4 className="selected_meals_title">Odabrana jela:</h4>
                    <List>
                        {mealsForMenu.map((meal, i) =>
                            <React.Fragment key={meal.id}>
                                <ListItem
                                    inputMode="numeric"
                                    divider
                                    sx={{ maxWidth: 760, bgcolor: "#fff" }}
                                    secondaryAction={
                                        <>
                                            <Checkbox
                                                checked={meal.nutritionisRecommended}
                                                onChange={() => handleCheckboxChange(meal.id)}
                                            />
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => { handleRemoveMeal(meal.id) }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <div>
                                        <h5>{i + 1}. {meal.name}</h5>
                                        <p>{meal.description}</p>
                                    </div>
                                </ListItem>
                            </React.Fragment>
                        )}
                    </List>
                    <FormControl sx={{ display: "grid", justifyContent: "end", mb: 2.5 }}>
                        <Button
                            label="Sačuvaj meni"
                            startIcon={<Save />}
                            disabled={mealsForMenu.length === 0}
                            className="btn_success"
                            handleClick={handleAddMenu}
                        />
                    </FormControl>
                </div>}
        </div>
    );
};

export default MenuForm;