import React, { FC } from "react";
import { Meal } from "../../store/reducers/menuState";
import { portionLabels, SelectedMeal } from "./Menu";
import { formatCurrency } from "../../helpers/utils";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";

interface MealItemProps {
    meal: Meal;
    mealIndex: number;
    handleAddToCart: (mealData: SelectedMeal) => void;
    userUid: string;
    canOrder: boolean;
}

const MealItem: FC<MealItemProps> = ({ meal, mealIndex, handleAddToCart, userUid, canOrder }) => {
    return (
        <div className="menu_item" style={meal.nutritionisRecommended ? { backgroundColor: "#DFF3D7" } : {}}>
            <div className="image_wrapper">
                {meal.soldOut && (
                    <div className="ribbon">
                        <span className="ribbon_soldout">Rasprodato</span>
                    </div>
                )}
                <img src={meal.imageUrl?.length ? meal.imageUrl : "./img/food.png"} alt={meal.name} />
            </div>
            <div className="menu_info">
                <div>
                    <h2 className="menu_name">{meal.name}</h2>
                    <p className="menu_desc">{meal.description}</p>
                    {!meal.soldOut && (
                        <div className="menu_size_wrapper">
                            {meal.portions.map((portion) => (
                                <div key={portion.id} className="menu_size">
                                    <h4 className="menu_item_size">
                                        {portionLabels[portion.size]}:
                                        <span className="menu_item_price">{formatCurrency(portion.price)} RSD</span>
                                    </h4>
                                    {(userUid && !canOrder) && (
                                        <Button
                                            label="Dodaj"
                                            endIcon={<Add />}
                                            className="btn_success"
                                            handleClick={() => handleAddToCart({
                                                id: meal.id,
                                                name: meal.name,
                                                portionId: portion.id,
                                                price: portion.price,
                                                size: portion.size,
                                                amount: 1,
                                                mealNumber: mealIndex + 1,
                                                mealType: meal.meal
                                            })}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {meal.nutritionisRecommended && (
                    <div onClick={() => toast.info("Kreirano od strane nutricioniste")} className="healthy_icon_container">
                        <img src="./img/healthy-food-icon.svg" height="45" title="Kreirano od strane nutricioniste" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default MealItem;