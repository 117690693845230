import React, { FC, useState } from "react";
import { formatCurrency } from "../../helpers/utils";
import { Meal } from "../../store/reducers/menuState";
import { portionLabels } from "../Menu/Menu";
import Input from "../UI/Input";
import Button from "../UI/Button";
import TablePaginationActions from "../UI/TablePaginationActions";
import MealModal from "./Modals/MealModal";
import DeleteMealModal from "./Modals/DeleteMealModal";
import { IconButton, Paper, Table, TableBody, TableFooter, TableCell, TableContainer, TablePagination, TableHead, TableRow } from "@mui/material";
import { Delete, Edit, Add } from "@mui/icons-material";

interface MenuFormProps {
    meals: Meal[];
}

const MealsForm: FC<MenuFormProps> = ({ meals }) => {
    const [openMealModal, setOpenMealModal] = useState<boolean>(false);
    const [openEditMealModal, setOpenEditMealModal] = useState<boolean>(false);
    const [openDeleteMealModal, setOpenDeleteMealModal] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [selectedMeal, setSelectedMeal] = useState<Meal>({
        id: "",
        name: "",
        meal: "",
        description: "",
        imageUrl: "",
        portions: [],
        soldOut: false
    });
    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const handleOpenEditMealModal = (meal: Meal): void => {
        setSelectedMeal(meal);
        setOpenEditMealModal(true);
    };

    const handleOpenDeleteMealModal = (meal: Meal): void => {
        setSelectedMeal(meal);
        setOpenDeleteMealModal(true);
    };

    const sortedMeals: Meal[] = [...meals].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

    const filteredMeals: Meal[] = sortedMeals.filter(meal =>
        meal.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        meal.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        meal.portions.some(portion => portion.price.toString().includes(searchTerm))
    );

    return (
        <div>
            <div className="search_input_container">
                <Input
                    label="Pretraga"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => { setSearchTerm(e.target.value) }}
                />
                <Button
                    label="Dodaj novo jelo"
                    startIcon={<Add />}
                    className="btn_success"
                    handleClick={() => setOpenMealModal(true)}
                />
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#E6B238" }}>
                            <TableCell sx={{ color: "#fff" }} width="350">Naziv</TableCell>
                            <TableCell sx={{ color: "#fff" }}>Opis</TableCell>
                            <TableCell sx={{ color: "#fff" }} width="250">Porcije</TableCell>
                            <TableCell sx={{ color: "#fff" }} width="120"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? filteredMeals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : filteredMeals
                        ).map(meal => (
                            <TableRow
                                key={meal.id}
                                sx={{
                                    "&:nth-of-type(odd)": {
                                        backgroundColor: "rgba(0,0,0,0.04)"
                                    },
                                    "&:last-child td, &:last-child th": { border: 0 }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {meal.name}
                                </TableCell>
                                <TableCell>{meal.description}</TableCell>
                                <TableCell>
                                    {meal.portions.map(portion =>
                                        <div key={portion.id}>
                                            <span>Porcija: <b>{portionLabels[portion.size]} </b></span>
                                            <span>Cena: <b>{formatCurrency(portion.price)} RSD</b></span>
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        sx={{ ml: 1 }}
                                        size="small"
                                        onClick={() => handleOpenEditMealModal(meal)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        sx={{ ml: 1 }}
                                        size="small"
                                        className="btn_delete"
                                        onClick={() => handleOpenDeleteMealModal(meal)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage="Redova po stranici"
                                count={filteredMeals.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                colSpan={4}
                                rowsPerPageOptions={[5, 10, 25, { label: "Svi", value: -1 }]}
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}–${to} od ${count !== -1 ? count : `više od ${to}`}`
                                }
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            {openMealModal &&
                <MealModal
                    open={openMealModal}
                    isAddMode
                    selectedMeal={selectedMeal}
                    onHandleClose={() => setOpenMealModal(false)}
                />}
            {openEditMealModal &&
                <MealModal
                    open={openEditMealModal}
                    isAddMode={false}
                    selectedMeal={selectedMeal}
                    onHandleClose={() => setOpenEditMealModal(false)}
                />}
            <DeleteMealModal
                open={openDeleteMealModal}
                selectedMeal={selectedMeal}
                onHandleClose={() => setOpenDeleteMealModal(false)}
            />
        </div >
    );
};

export default MealsForm;