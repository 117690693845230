import React, { FC } from "react";
import { Checkbox as MuiCheckbox } from "@mui/material";

interface CheckboxProps {
    checked: boolean | undefined;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<CheckboxProps> = ({ checked, disabled, onChange }) => {
    return (
        <MuiCheckbox
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            sx={{
                color: "#6f9a37",
                "&.Mui-checked": {
                    color: "#6f9a37"
                }
            }}
        />
    );
};

export default Checkbox;