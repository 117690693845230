import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../index";
import * as menuActions from "../../store/actions/menuActions";
import authState from "../../store/reducers/authState";
import menuState, { DisabledOatmealDates, Meal } from "../../store/reducers/menuState";
import { generateMealMenuImage } from "../../helpers/utils";
import { STATUS_ENUM } from "../../store/reducers/menuReducer";
import moment, { Moment } from "moment";
import "moment/locale/sr";
import Loader from "../UI/Loader";
import TabPanel from "./TabPanel";
import MealItem from "./MealItem";
import Button from "../UI/Button";
import Sidebar from "./Sidebar";
import SuccessfulOrderModal from "./Modals/SuccessfulOrderModal";
import OrderForCurrentUserModal from "./Modals/OrderForCurrentUserModal";
import GenerateGramMenuModal from "./Modals/GenerateGramMenuModal";
import Container from "react-bootstrap/Container";
import { Box, Divider, Tabs, Tab, useMediaQuery } from "@mui/material";
import { Download, Person, Restaurant } from "@mui/icons-material";
import { toast } from "react-toastify";

export interface SelectedMeal {
    id: string;
    name: string;
    portionId: string;
    price: number;
    size: string;
    amount: number;
    mealNumber: number;
    mealType?: string;
}

export interface Order {
    id: string;
    address: string;
    phoneNumber: string;
    customerId: string;
    customerName: string;
    customerSubvention: number;
    meals: SelectedMeal[];
    note: string;
    deliveryTime: string;
    deliveryDate: string;
    deliveryTimestamp: number;
    createdAt: number;
    createdUserId: string;
    createdUserFullName: string;
    createdUserEmail: string;
    orderStatus: string;
}

interface PortionLabel {
    [key: string]: string;
}

export const portionLabels: PortionLabel = {
    "small": "Mala",
    "big": "Velika",
    "regular": "Obrok",
    "weight": "100g"
}

const Menu: FC = () => {
    const [workingDays, setWorkingDays] = useState<Moment[]>([]);
    const [tabValue, setTabValue] = useState<number>(0);
    const [mealTabValue, setMealTabValue] = useState<number>(0);
    const [selectedMeals, setSelectedMeals] = useState<SelectedMeal[]>([]);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openOrderForCurrentUserModal, setOpenOrderForCurrentUserModal] = useState<boolean>(false);
    const [openGenerateGramMenuModal, setOpenGenerateGramMenuModal] = useState<boolean>(false);

    const isSmallScreen: boolean = useMediaQuery("(max-width: 768px)");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { fetchMenu, fetchOatmeals, fetchDisabledOatmealDates, saveOrder } = bindActionCreators(menuActions, dispatch);

    const menuState: menuState = useSelector((state: state) => state.menu);
    const authState: authState = useSelector((state: state) => state.auth);
    const userUid: string = authState.userUid;

    useEffect(() => {
        getWorkingDays();
        fetchOatmeals();
        fetchDisabledOatmealDates();
    }, []);

    useEffect(() => {
        if (workingDays.length) {
            fetchMenu(workingDays[0].startOf("day").unix());
        }
    }, [workingDays]);

    useEffect(() => {
        if (menuState.status === STATUS_ENUM.SUCCESS) {
            setOpenSuccessModal(true);
        }
    }, [menuState.status]);

    const getWorkingDays = (): void => {
        const days: Moment[] = [];
        let count: number = 0;
        const date: Moment = moment();

        while (count < 10) {
            const currentDate: Moment = count === 0 ? date.startOf("day") : date.startOf("day").clone().add(count, "days");
            //Increment count
            count++;
            if (!(isWeekday(currentDate.day()))) {
                continue;
            }
            days.push(currentDate);
        }

        setWorkingDays(days.slice(0, 5));
    };

    const isWeekday = (day: number): boolean => {
        return day !== 0 && day !== 6;
    };

    const isDateDisabled = (date: Moment): boolean => {
        const disabledOatmealDates = menuState?.disabledOatmealDates;

        return !disabledOatmealDates.some((disabledDate: DisabledOatmealDates) => {
            return (
                moment(date).isSameOrAfter(moment(disabledDate.startDate)) &&
                moment(date).isSameOrBefore(moment(disabledDate.endDate))
            );
        });
    };

    const handleChangeTabValue = (e: React.SyntheticEvent, newValue: number): void => {
        setTabValue(newValue);
        setSelectedMeals([]);
        setTotalAmount(0);
    };

    const handleChangeMealTabValue = (newValue: number): void => {
        setMealTabValue(newValue);
    };

    const tabProps = (index: number) => {
        return {
            id: `vertical-tab-${index}`,
            "aria-controls": `vertical-tabpanel-${index}`,
        };
    };

    const handleAddToCart = (meal: SelectedMeal): void => {
        //Finding the accurate Total Amount
        const updatedTotalAmount = totalAmount + meal.price * 1;
        setTotalAmount(updatedTotalAmount);

        const existingItemIndex = selectedMeals.findIndex(s => s.portionId === meal.portionId);
        const existingItem = selectedMeals[existingItemIndex];
        let updatedItems: SelectedMeal[] = [];
        if (existingItem) {
            const updatedItem = { ...existingItem, amount: existingItem.amount + 1 };
            updatedItems = [...selectedMeals];
            updatedItems[existingItemIndex] = updatedItem;
        } else {
            updatedItems = selectedMeals.concat(meal);
        }

        setSelectedMeals(updatedItems);
    };

    const handleRemoveFromCart = (meal: SelectedMeal): void => {
        const existingItemIndex = selectedMeals.findIndex(s => s.portionId === meal.portionId);
        const existingItem = selectedMeals[existingItemIndex];

        //Finding the accurate Total Amount
        const updatedTotalAmount = totalAmount - existingItem.price;
        setTotalAmount(updatedTotalAmount);
        let updatedItems: SelectedMeal[] = [];
        if (existingItem.amount === 1) {
            updatedItems = selectedMeals.filter(s => s.portionId !== meal.portionId);
        } else {
            const updatedItem = { ...existingItem, amount: existingItem.amount - 1 };
            updatedItems = [...selectedMeals];
            updatedItems[existingItemIndex] = updatedItem;
        }

        setSelectedMeals(updatedItems);
    };

    const handleEmptyCart = (): void => {
        setSelectedMeals([]);
        setTotalAmount(0);
        toast.success("Porudžbina je usprešno obrisana.");
    };

    const handleDownloadMealMenu = (): void => {
        const meals: Meal[] = menuState.menu?.meals || [];
        generateMealMenuImage(workingDays[tabValue], meals);
    };

    const handleDownloadGramMenu = (): void => {
        setOpenGenerateGramMenuModal(true);
    };

    const handleSaveOrder = (note: string, subvention: number): void => {
        if (!canOrder) {
            const soldOutMeals: Meal[] = menuState?.menu?.meals.filter(meal => meal.soldOut);
            const soldOutSelectedMeals: SelectedMeal[] = selectedMeals.filter(meal => soldOutMeals.find(soldOutMeal => soldOutMeal.id === meal.id));

            if (!soldOutSelectedMeals.length && authState.user.isActive) {
                const deliveryDate: Moment = workingDays[tabValue];
                const order: Order = {
                    id: "",
                    address: authState.user.address,
                    phoneNumber: authState.user.phoneNumber,
                    customerId: authState.user.customerId,
                    customerName: authState.user.customerName,
                    customerSubvention: subvention,
                    meals: selectedMeals,
                    note: note,
                    deliveryDate: deliveryDate.format("DD/MM/YYYY"),
                    deliveryTime: authState.user.deliveryTime,
                    deliveryTimestamp: deliveryDate.startOf("day").unix(),
                    createdAt: moment().unix(),
                    createdUserId: authState.userUid,
                    createdUserFullName: authState.user.fullName,
                    createdUserEmail: authState.user.email,
                    orderStatus: "preparation"
                };

                setSelectedMeals([]);
                setTotalAmount(0);
                saveOrder(order);
            } else if (!authState.user.isActive) {
                toast.error("Korisnik nije aktivan.");
                setSelectedMeals([]);
                setTotalAmount(0);
            } else {
                toast.error("Porudžbina nije dobra jer sadrži artikal koji je rasprodat.");
            }
        } else {
            navigate("/");
        }
    };

    if (menuState.loading) return <Loader />;

    const canOrder =
        (!authState?.user?.canOrderCurrentDay && moment().startOf("day").isSame(workingDays[tabValue])) ||
        (authState?.user?.canOrderCurrentDay && moment().startOf("day").isSame(workingDays[tabValue]) && moment().isAfter(moment({ hour: 22, minute: 0, second: 0 })));

    return (
        <div className="menu_container">
            <Container style={{ marginTop: 40 }}>
                <Box className="tabs_container">
                    <Tabs
                        value={tabValue}
                        onChange={handleChangeTabValue}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="Vertical menu tabs"
                        sx={{
                            borderColor: "divider",
                            "& .MuiTabs-flexContainer": {
                                flexDirection: isSmallScreen ? "row" : "column"
                            },
                            "& .MuiTabs-indicator": {
                                background: "#fff"
                            }
                        }}
                    >
                        {workingDays.map((day: Moment, i: number) =>
                            <Tab
                                key={day.format("DD/MM/YYYY")}
                                label={day.locale("sr").format("DD ddd").substring(0, 6)}
                                {...tabProps(i)}
                                onClick={() => {
                                    fetchMenu(day.startOf("day").unix());
                                    setMealTabValue(0);
                                }}
                                sx={{
                                    background: "#fff",
                                    color: "#818181",
                                    border: "1px solid #e5e5e5",
                                    marginBottom: "2px",
                                    minWidth: "100px",
                                    "&.MuiTab-root.Mui-selected": {
                                        background: "#BBD870",
                                        color: "#5C5E60"
                                    }
                                }}
                            />
                        )}
                    </Tabs>
                    <div className="menu_items">
                        {(userUid && workingDays.length && !canOrder && menuState?.menu?.meals.length > 0) &&
                            <Sidebar
                                selectedDate={workingDays[tabValue]}
                                selectedMeals={selectedMeals}
                                totalAmount={totalAmount}
                                addItemFromCart={handleAddToCart}
                                removeItemFromCart={handleRemoveFromCart}
                                emptyCart={handleEmptyCart}
                                saveOrder={handleSaveOrder}
                            />}

                        {(userUid && workingDays.length && menuState?.menu?.meals.length > 0) &&
                            <Box sx={{ mb: 2 }}>
                                <Button
                                    label="Vaša porudžbina"
                                    startIcon={<Person />}
                                    className="btn_success"
                                    handleClick={() => { setOpenOrderForCurrentUserModal(true) }}
                                />
                                {authState.user.role === "admin" &&
                                    <>
                                        <Button
                                            label="Obrok meni"
                                            startIcon={<Download />}
                                            className="btn_success"
                                            sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 1, sm: 0 } }}
                                            handleClick={handleDownloadMealMenu}
                                        />
                                        <Button
                                            label="Gramaža meni"
                                            startIcon={<Download />}
                                            className="btn_success"
                                            sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 1, sm: 0 } }}
                                            handleClick={handleDownloadGramMenu}
                                        />
                                    </>}
                            </Box>}

                        <Box
                            className="btn_meal_container"
                            sx={{ my: 1.5 }}
                        >
                            <Button
                                label="Ručak"
                                startIcon={<Restaurant />}
                                className={mealTabValue === 0 ? "btn_selected_meal" : "btn_meal"}
                                handleClick={() => handleChangeMealTabValue(0)}
                            />
                            {isDateDisabled(workingDays[tabValue]) ? (
                                <Button
                                    label="Overnight ovsene kaše"
                                    startIcon={<img src={mealTabValue === 1 ? "./img/oatmeal_icon_w.png" : "./img/oatmeal_icon.png"} height="25" />}
                                    className={mealTabValue === 1 ? "btn_selected_meal" : "btn_meal"}
                                    handleClick={() => handleChangeMealTabValue(1)}
                                />
                            ) : null}
                        </Box>

                        {mealTabValue === 0 && workingDays.map((day: Moment, i: number) =>
                            <TabPanel value={tabValue} index={i} key={i}>
                                {menuState.menu?.meals?.length > 0 ?
                                    menuState.menu?.meals.map((meal: Meal, i: number) =>
                                        <MealItem
                                            key={meal.id}
                                            meal={meal}
                                            mealIndex={i}
                                            handleAddToCart={handleAddToCart}
                                            userUid={userUid}
                                            canOrder={canOrder}
                                        />
                                    )
                                    :
                                    <Divider sx={{ my: 2 }}>
                                        <h3 className="main_title">Jelovnik još uvek nije kreiran</h3>
                                    </Divider>
                                }
                            </TabPanel>
                        )}

                        {mealTabValue === 1 &&
                            <div>
                                {menuState.oatmeals.map((meal: Meal, i: number) =>
                                    <MealItem
                                        key={meal.id}
                                        meal={meal}
                                        mealIndex={i}
                                        handleAddToCart={handleAddToCart}
                                        userUid={userUid}
                                        canOrder={canOrder}
                                    />
                                )}
                            </div>
                        }
                    </div>
                </Box>
            </Container>

            {openSuccessModal &&
                <SuccessfulOrderModal
                    open={openSuccessModal}
                    orderId={menuState.orderId}
                    onHandleClose={() => setOpenSuccessModal(false)}
                />}

            {openOrderForCurrentUserModal &&
                <OrderForCurrentUserModal
                    open={openOrderForCurrentUserModal}
                    selectedDate={workingDays[tabValue]}
                    onHandleClose={() => setOpenOrderForCurrentUserModal(false)}
                />
            }

            {openGenerateGramMenuModal &&
                <GenerateGramMenuModal
                    open={openGenerateGramMenuModal}
                    menuMeals={menuState.menu?.meals || []}
                    selectedDate={workingDays[tabValue]}
                    onHandleClose={() => setOpenGenerateGramMenuModal(false)}
                />}
        </div>
    );
};

export default Menu;