import React from "react";
import { Meal } from "../../../store/reducers/menuState";
import PortionRow from "../../Settings/Modals/PortionRow";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import { Box, IconButton, List, ListItem, SelectChangeEvent } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

interface MealRowProps {
    meal: Meal;
    index: number;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent, i: number, portionIndex?: number) => void;
    handleAddPortionRow: (mealIndex: number) => void;
    handleRemovePortionRow: (mealIndex: number, portionId: string) => void;
    handleSelectChange: (e: SelectChangeEvent, mealIndex: number, portionIndex: number) => void;
    handleDeleteMealRow: (mealId: string) => void;
}

const MealRow: React.FC<MealRowProps> = ({ meal, index, handleInputChange, handleAddPortionRow, handleRemovePortionRow, handleSelectChange, handleDeleteMealRow }) => {
    return (
        <Box sx={{ bgcolor: "#fff", display: "flex", gap: 1, alignItems: "center", flexDirection: { xs: "column", sm: "row" } }}>
            <Box sx={{ width: { xs: "100%", sm: "20%" } }}>
                <Input
                    label="Ime"
                    type="text"
                    value={meal.name}
                    name="name"
                    required
                    onChange={(e) => handleInputChange(e, index)}
                />
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "40%" }, }}>
                <Input
                    label="Opis"
                    type="text"
                    value={meal.description}
                    name="description"
                    onChange={(e) => handleInputChange(e, index)}
                />
            </Box>
            <IconButton color="default" onClick={() => handleDeleteMealRow(meal.id)}>
                <Delete />
            </IconButton>
            <Button
                label="Dodaj porciju"
                startIcon={<Add />}
                className="btn_success"
                handleClick={() => handleAddPortionRow(index)}
            />
            <List>
                {meal.portions.map((portion, portionIndex) => (
                    <React.Fragment key={portion.id}>
                        <ListItem
                            inputMode="numeric"
                            divider
                            sx={{ maxWidth: 760, bgcolor: "#fff" }}
                            secondaryAction={
                                <IconButton
                                    sx={{ ml: 1 }}
                                    size="small"
                                    color="error"
                                    onClick={() => handleRemovePortionRow(index, portion.id)}
                                >
                                    <Delete />
                                </IconButton>
                            }
                        >
                            <PortionRow
                                index={portionIndex}
                                portion={portion}
                                portionOptions={[
                                    { id: "regular", value: "Obrok" },
                                    { id: "small", value: "Mala" },
                                    { id: "big", value: "Velika" },
                                    { id: "weight", value: "100g" }
                                ]}
                                handlePortionSelectChange={(e) => handleSelectChange(e, index, portionIndex)}
                                handlePortionInputChange={(e) => handleInputChange(e, index, portionIndex)}
                            />
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};

export default MealRow;