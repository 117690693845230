import React, { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Meal, Portion } from "../../../store/reducers/menuState";
import { generateGramMenuImage } from "../../../helpers/utils";
import { Moment } from "moment";
import "moment/locale/sr";
import Button from "../../UI/Button";
import MealRow from "./MealRow";
import { Box, Divider, Modal, ModalClose, ModalDialog, ModalOverflow, Typography } from "@mui/joy";
import { SelectChangeEvent } from "@mui/material";
import { Add, Download } from "@mui/icons-material";

interface GenerateGramMenuModalProps {
    open: boolean;
    menuMeals: Meal[];
    selectedDate: Moment;
    onHandleClose: () => void;
}

const MAX_ADDITIONAL_ROWS = 3;

const GenerateGramMenuModal: FC<GenerateGramMenuModalProps> = ({ open, menuMeals, selectedDate, onHandleClose }) => {
    const [localMeals, setLocalMeals] = useState<Meal[]>(menuMeals);
    const [extraRows, setExtraRows] = useState<number>(0);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent, mealIndex: number, portionIndex?: number): void => {
        const { name, value } = e.target;
        setLocalMeals((prevMeals) =>
            prevMeals.map((meal, index) => {
                if (index === mealIndex) {
                    if (portionIndex !== undefined) {
                        const updatedPortions = meal.portions.map((portion, pIndex) =>
                            pIndex === portionIndex
                                ? { ...portion, [name]: value }
                                : portion
                        );
                        return { ...meal, portions: updatedPortions };
                    } else {
                        return { ...meal, [name]: value };
                    }
                }
                return meal;
            })
        );
    };

    const handleSelectChange = (e: SelectChangeEvent, mealIndex: number, portionIndex: number): void => {
        const { name, value } = e.target;

        setLocalMeals((prevMeals) =>
            prevMeals.map((meal, index) => {
                if (index === mealIndex) {
                    const updatedPortions = meal.portions.map((portion, pIndex) =>
                        pIndex === portionIndex
                            ? { ...portion, [name]: value }
                            : portion
                    );
                    return { ...meal, portions: updatedPortions };
                }
                return meal;
            })
        );
    };

    const handleAddPortionRow = (mealIndex: number): void => {
        const newPortion: Portion = { id: uuidv4(), size: "", price: 0 };

        setLocalMeals((prevMeals) =>
            prevMeals.map((meal, index) => {
                if (index === mealIndex) {
                    return { ...meal, portions: [...meal.portions, newPortion] };
                }
                return meal;
            })
        );
    };

    const handleRemovePortionRow = (mealIndex: number, portionId: string): void => {
        setLocalMeals((prevMeals) =>
            prevMeals.map((meal, index) => {
                if (index === mealIndex) {
                    return { ...meal, portions: meal.portions.filter((portion) => portion.id !== portionId) };
                }
                return meal;
            })
        );
    };

    const handleAddMealRow = (): void => {
        if (extraRows < MAX_ADDITIONAL_ROWS) {
            const newMeal: Meal = { id: uuidv4(), name: "", description: "", meal: "", imageUrl: "", portions: [] };

            setLocalMeals((prevMeals) => {
                //Calculate the insertion index after the base rows and any additional rows
                const insertionIndex = 8 + extraRows;
                const updatedMeals = [...prevMeals];
                updatedMeals.splice(insertionIndex, 0, newMeal);
                return updatedMeals;
            });

            setExtraRows((prevExtraRows) => prevExtraRows + 1);
        }
    };

    const handleDeleteMealRow = (mealId: string): void => {
        setLocalMeals((prevMeals) => prevMeals.filter((meal) => meal.id !== mealId));
        setExtraRows((prevExtraRows) => prevExtraRows - 1);
    };

    const handleDownloadMenu = (): void => {
        generateGramMenuImage(selectedDate, localMeals);
        onHandleClose();
    };

    return (
        <div>
            <Modal open={open} onClose={onHandleClose}>
                <ModalOverflow>
                    <ModalDialog
                        variant="outlined"
                        sx={{ width: "100%" }}
                        aria-labelledby="user-orders-dialog-modal-title"
                        aria-describedby="user-orders-dialog-description"
                    >
                        <ModalClose variant="outlined" />
                        <Typography id="user-orders-dialog-modal-title" level="h2">
                            Meni za: {selectedDate.format("DD.MM.YYYY.")}
                        </Typography>
                        {localMeals.map((meal, i) => (
                            <div
                                key={meal.id}
                                style={{
                                    display: meal.meal === "extraBread" || meal.meal === "extraSalad" ? "none" : "block",
                                }}
                            >
                                <MealRow
                                    meal={meal}
                                    index={i}
                                    handleInputChange={handleInputChange}
                                    handleSelectChange={handleSelectChange}
                                    handleAddPortionRow={handleAddPortionRow}
                                    handleRemovePortionRow={handleRemovePortionRow}
                                    handleDeleteMealRow={handleDeleteMealRow}
                                />
                            </div>
                        ))}
                        {extraRows < MAX_ADDITIONAL_ROWS && (
                            <Box sx={{ display: "flex", gap: 1, justifyContent: "center", p: 2 }}>
                                <Button
                                    label="Dodaj jelo"
                                    startIcon={<Add />}
                                    className="btn_outlined"
                                    handleClick={handleAddMealRow}
                                />
                            </Box>
                        )}
                        <Divider />
                        <Box sx={{ display: "flex", gap: 1, justifyContent: "center", p: 2 }}>
                            <Button
                                label="Preuzmi meni"
                                startIcon={<Download />}
                                className="btn_success"
                                handleClick={handleDownloadMenu}
                            />
                        </Box>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
        </div>
    );
};

export default GenerateGramMenuModal;