import React, { FC, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../index";
import * as authActions from "../../store/actions/authActions";
import * as settingsActions from "../../store/actions/settingsActions";
import * as menuActions from "../../store/actions/menuActions";
import authState, { User } from "../../store/reducers/authState";
import settingsState from "../../store/reducers/settingsState";
import menuState, { Meal, Portion } from "../../store/reducers/menuState";
import { Customer } from "../auth/Auth";
import { Order, SelectedMeal } from "../Menu/Menu";
import { calculateUserSubvention } from "../../helpers/utils";
import moment, { Moment } from "moment";
import "moment/locale/sr";
import Input from "../UI/Input";
import Button from "../UI/Button";
import Autocomplete from "../UI/Autocomplete";
import Select from "../UI/Select";
import GroupingMealsSelect from "./GroupingMealsSelect";
import { SelectChangeEvent } from "@mui/material";
import { Save } from "@mui/icons-material";
import { toast } from "react-toastify";

interface AddOrderData {
    customerId: string;
    user: User | null;
    portionIds: string[];
    note: string;
}

const AddOrder: FC = () => {
    const [addOrderData, setAddOrderData] = useState<AddOrderData>({
        customerId: "",
        user: null,
        portionIds: [],
        note: ""
    });
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { fetchCustomers } = bindActionCreators(authActions, dispatch);
    const { fetchUsers } = bindActionCreators(settingsActions, dispatch);
    const { fetchMenu, fetchUserOrders } = bindActionCreators(menuActions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);
    const settingsState: settingsState = useSelector((state: state) => state.settings);
    const menuState: menuState = useSelector((state: state) => state.menu);
    const users: User[] = settingsState.users.slice().sort((a, b) => (a.email < b.email ? -1 : a.email > b.email ? 1 : 0));
    const customers: Customer[] = authState.customers;
    const meals: Meal[] = menuState.menu?.meals;
    const userOrders: Order[] = menuState.userOrders;

    useEffect(() => {
        fetchCustomers();
        fetchMenu(moment().startOf("day").unix());
    }, []);

    const handleSelectChange = (e: SelectChangeEvent): void => {
        const { name, value } = e.target;

        if (name === "customerId") {
            setAddOrderData({ ...addOrderData, [name]: value });
            fetchUsers(value);
        } else {
            setAddOrderData({ ...addOrderData, [name]: value });
        }
    };

    const handleAutoCompleteChange = (e: React.ChangeEvent<{}>, value: User | null) => {
        setAddOrderData({ ...addOrderData, user: value });
        fetchUserOrders(moment().format("DD/MM/YYYY"), value?.id);
    };

    const handleSaveOrder = async (): Promise<void> => {
        setIsDisabled(true);
        const selectedMeals: SelectedMeal[] = addOrderData.portionIds.map(portionId => {
            const meal: Meal = meals.find(m => m.portions.some(p => p.id === portionId))!;
            const portion: Portion = meal.portions.find(p => p.id === portionId)!;
            const mealIndex: number = meals.findIndex(m => m.id === meal.id);

            const selectedMeal: SelectedMeal = {
                id: meal.id,
                name: meal.name,
                portionId: portion.id,
                price: portion.price,
                size: portion.size,
                amount: 1,
                mealNumber: mealIndex + 1,
                mealType: meal.meal
            };

            return selectedMeal;
        });

        const id: string = uuidv4();
        const deliveryDate: Moment = moment();
        const user: User = users.filter(user => user.id === addOrderData.user?.id)[0];
        const totalAmount: number = selectedMeals.reduce((mealAcc, meal) => {
            return mealAcc + (meal.amount * meal.price);
        }, 0);
        const subvention: number = calculateUserSubvention(user.customerSubvention, userOrders, totalAmount);

        const order: Order = {
            id: "",
            address: user.address,
            phoneNumber: user.phoneNumber,
            customerId: user.customerId,
            customerName: user.customerName,
            customerSubvention: subvention,
            meals: selectedMeals,
            note: addOrderData.note,
            deliveryDate: deliveryDate.format("DD/MM/YYYY"),
            deliveryTime: user.deliveryTime,
            deliveryTimestamp: deliveryDate.startOf("day").unix(),
            createdAt: moment().unix(),
            createdUserId: user.id,
            createdUserFullName: user.fullName,
            createdUserEmail: user.email,
            orderStatus: "preparation"
        };

        try {
            await setDoc(doc(db, "orders", id), { ...order, id: id });
            setIsDisabled(false);
            toast.success("Usprešno ste dodali porudžbinu.");
            setAddOrderData({
                customerId: "",
                user: null,
                portionIds: [],
                note: ""
            });
        } catch (error) {
            console.error(error);
            setIsDisabled(false);
            toast.error(`Greška pri dodavanju porudžbine: ${error}`);
        }
    };

    return (
        <div>
            <div className="input_container">
                <Select
                    label="Kompanija"
                    value={addOrderData.customerId}
                    options={customers.map(customer => ({ key: customer.id, value: customer.name }))}
                    name="customerId"
                    onSelectChange={handleSelectChange}
                />
                <Autocomplete<User>
                    options={users}
                    label="Korisnik"
                    value={addOrderData.user}
                    onChange={handleAutoCompleteChange}
                    getOptionLabel={(option) => option.email}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.email}
                        </li>
                    )}
                />
                <GroupingMealsSelect
                    label="Jela"
                    value={addOrderData.portionIds}
                    meals={meals}
                    name="portionIds"
                    multiple
                    onSelectChange={handleSelectChange}
                />
            </div>
            <div className="input_container">
                <Input
                    label="Napomena"
                    type="text"
                    value={addOrderData.note}
                    name="note"
                    onChange={(e) => setAddOrderData({ ...addOrderData, note: e.target.value })}
                />
                <Button
                    label="Sačuvaj"
                    startIcon={<Save />}
                    disabled={isDisabled}
                    className="btn_success"
                    handleClick={handleSaveOrder}
                />
            </div>
        </div>
    );
};

export default AddOrder;